






































































































































import Vue from 'vue';
import moment from 'moment';
import * as api from '@/api/tenantManage';
export default Vue.extend({
  name: 'tenantManageList',
  data() {
    return {
      listLoading: false,
      certificationType: 'enterprise',
      searchForm: {
        tenantName: '',
        agencyCode: '',
        mobile: '',
      },
      listData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
        onChange: (page, pageSize) => {
          this['pagination'].current = page;
          this['pagination'].pageSize = pageSize;
          this['getTenantList']();
        },
        onShowSizeChange: (current, size) => {
          this['pagination'].current = current;
          this['pagination'].pageSize = size;
          this['getTenantList']();
        },
      },
    };
  },
  mounted() {
    this.getTenantList();
  },
  methods: {
    moment,
    // 转换认证信息
    createAuditStateText(val) {
      if (val == 0) {
        return '审核中';
      } else if (val == 1) {
        return '已认证';
      } else if (val == 2) {
        return '认证不通过';
      } else {
        return '未知';
      }
    },
    createAppInfoText(val) {
      if (val) {
        const text = val.map((item) => {
          const statusText = item.recStatus == 'A' ? '正常' : '失效';
          return statusText + '-' + item.productCodeName;
        });
        return text.join('、');
      }
    },
    searchList() {
      this.pagination.current = 1;
      this.getTenantList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getTenantList();
    },
    titleClick(id: string) {
      // 跳转的企业认证审核详情页
      if (this.certificationType === 'enterprise') {
        this.$router.push({
          path: '/platform/tenantManage/details',
          query: { id: id },
        });
      }
      // 跳转的个人认证审核详情页
      if (this.certificationType === 'personal') {
        this.$router.push({
          path: '/platform/tenantManage/personalDetails',
          query: { id: id },
        });
      }
    },
    getTenantList() {
      if (this.certificationType === 'enterprise') {
        this.getEnterpriseTenantList();
      }
      if (this.certificationType === 'personal') {
        this.getPersonalTenantList();
      }
    },
    getEnterpriseTenantList() {
      this.listLoading = true;
      api
        .getEnterpriseTenantList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.tenantName,
          this.searchForm.agencyCode,
          this.searchForm.mobile
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.listData = res.result.list;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    getPersonalTenantList() {
      this.listLoading = true;
      api
        .getPersonalTenantList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.tenantName,
          this.searchForm.agencyCode,
          this.searchForm.mobile
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.listData = res.result.list;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    certificationTypeChange() {
      this.handleSearchReset();
    },
  },
});
